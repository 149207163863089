import React, { useEffect, useRef, useState } from "react"
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid } from "@material-ui/core";
import { Skeleton } from '@material-ui/lab'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Scrollbar, Navigation, Pagination, Autoplay } from "swiper"
import 'swiper/swiper-bundle.css'
import FeaturedCard from "./FeaturedCard";

SwiperCore.use([Scrollbar,Navigation,Pagination,Autoplay]);

const useStyles = makeStyles((theme) => ({
	mySwiper: {
    margin: "2em auto",
    padding: "0 1em",
		minHeight: 500,
    boxSizing: "border-box",
    "& .swiper-slide" : {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down(600)] : {
        width: "100%",
      },
      [`${theme.breakpoints.between(600,769)} and (orientation: landscape)`] : {
        width: "50%",
      },
      [theme.breakpoints.up(769)] : {
        width: "33.3333333%",
      },
    },
    "& .swiper-pagination-bullet" : {
      width: 25,
      height: 25,
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 12,
      background: "none",
      color: "#b6b4b2",
      opacity: 1,
    },
    "& .swiper-pagination-bullet-active" : {
      background: theme.palette.primary.light,
      color: "white"
    },
  },
  "& .swiper-slide img" : {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  navButtons : {
    display: "flex",
    justifyContent: "space-around",
    transform: "translateY(19px)",
    [theme.breakpoints.down(600)] : {
      justifyContent: "space-between"
    }
  }
}));


export default function FeaturedSwiper() {
  const classes = useStyles()
  var propiedades = useSelector((state) => state.properties)
  const [ sortedEstates, setSortedEstates ] = useState(null)
  const [ loading, setLoading ] = useState(true)
  const [ count, setCount ] = useState(0)
  
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  var skeleton = [];
  var cards = (window.innerWidth <= 768) ? 1 : 3
  for (var i = 0; i < cards; i++) {
    skeleton.push(
      <Grid item xs={12} sm={6} md={4} className={classes.skeletonCard} key={i}>
        <Skeleton animation="wave" variant="rect" height={200} width="100%" style={{marginBottom: 5}}/>
        <Skeleton animation="wave" height={25} width="40%" />
        <Skeleton animation="wave" height={25} width="100%" />
        <Skeleton animation="wave" height={25} width="90%" style={{marginBottom: 30}}/>
        <Skeleton animation="wave" height={25} width="20%" />
        <div style={{display: "flex"}}>
          <Skeleton animation="wave" height={40} width="80%" style={{marginRight: 5}}/>
          <Skeleton animation="wave" height={40} width="20%" style={{marginLeft: 5}}/>
        </div>
    </Grid>
    );
  }
  
  const sortPropiedades = () => {
    setSortedEstates(
        propiedades.Ofertas.sort((a,b) => {
            return new Date(b.UltimaModificacion) - new Date(a.UltimaModificacion)
        })
    )
  }
  
  useEffect(() => {
    if (propiedades) { 
      sortPropiedades()
      setLoading(false)
    }
    else {
      setTimeout(() => {
        setCount(count+1)
      }, 2000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  return (
    <>
      <Swiper
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        pagination={{
          "clickable": true,
          "dynamicBullets": true,
          "dynamicMainBullets": 4,
          "renderBullet": function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
          }
        }}
        loop={false}
        loopedSlides={6}
        simulateTouch={false}
        breakpoints={{
          "0": {
            "slidesPerView": 1,
            "slidesPerGroup": 1,
            "spaceBetween": 0,
          },
          "600": {
            "slidesPerView": 2,
            "slidesPerGroup": 2,
            "spaceBetween": 20
          },
          "768": {
            "slidesPerView": 3,
            "slidesPerGroup": 3,
            "spaceBetween": 15
          },
        }}
        className={classes.mySwiper}>
        
        { loading ? 
          <>
            <Grid container spacing={3}>
              {skeleton}
            </Grid>
          </> 
          :
          <>
            {sortedEstates.slice(0,6).map((oferta) => (
              <SwiperSlide key={oferta.CodOferta}>
                <FeaturedCard codInmobiliaria={oferta.CodInmobiliaria} codOferta={oferta.CodOferta} titulo={oferta.Titulo} />
              </SwiperSlide>
            ))}
          </>
          
        }
        

        <div className={classes.navButtons}>
          <Button size="small" ref={prevRef}>Anterior</Button>
          <Button size="small" ref={nextRef}>Siguiente</Button>
        </div>
        
      </Swiper>
    </>
  )
}
