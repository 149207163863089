import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
import emailjs from 'emailjs-com'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: "6px 0",
    },
    '& label': {
      color: "#9d9b99",
      [theme.breakpoints.between(600,768)] : {
        fontSize: 12,
      },
    },
		'& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "30px !important",
      },
      '&.MuiOutlinedInput-root fieldset': {
        background: "rgba(0,0,0,0.05)",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: theme.palette.text.secondary,
      [theme.breakpoints.between(600,768)] : {
        fontSize: 12,
      },
    },
  },
  sendButton: {
    display: "flex",
    margin: "auto",
    borderRadius: "30px",
    "& .MuiButton-label": {
      margin: "0px 30px"
    }
  }
}));

export default function ContactForm(props) {
  const classes = useStyles()
  const form = useRef()

  const sendEmail = e => {
      e.preventDefault()

      emailjs.sendForm('service_91iwly2', 'template_1c32az8', form.current, 'user_inQ0Mukub1IrfDBVkTFz9')
      .then((result) => {
          alert("El mensaje fue enviado con éxito")
      }, (error) => {
          console.log(error.text)
      })
  }

  return (
    <form id="formulario-correo" ref={form} className={classes.root} noValidate autoComplete="off" onSubmit={sendEmail}>
            <TextField name="nombre" className="form-control" id="nombre" label="Nombre y Apellido" size="small" margin="none" fullWidth required variant="outlined" color="primary" style={{marginTop: 16}} />
            <TextField name="email" className="form-control" id="email" label="Email" size="small" margin="none" fullWidth required variant="outlined" color="primary" />
            <TextField name="asunto" className="form-control" id="asunto" size="small" margin="none" fullWidth variant="outlined" color="primary" value={props.asunto} />
            <TextField name="mensaje" className="form-control" id="mensaje" label="Mensaje" size="medium" margin="none" fullWidth required multiline minRows={4} variant="outlined" color="primary" />

            <Button className={classes.sendButton} variant="contained" size="large" color="primary" type="submit">Enviar</Button>
    </form>
  );
}