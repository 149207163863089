import React, { Fragment, useState } from "react";
import {
	makeStyles,
	Box,
	Container,
	Typography,
	Popover,
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";

const useStyle = makeStyles((theme) => ({
	topBarDesk: {
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.down(769)]: {
			display: "none",
		},
	},
	topBarMobile: {
		backgroundColor: theme.palette.primary.main,
		display: "flex",
		justifyContent: "flex-end",
		[theme.breakpoints.up(769)]: {
			display: "none",
		},
	},
	phoneContainer: {
		backgroundColor: theme.palette.primary.light,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "fit-content",
		padding: "10px 14px",
	},
	text: {
		fontSize: "15px",
		[theme.breakpoints.between(769, 980)]: {
			fontSize: "11px",
		},
	},
	textMobile: {
		padding: ".5em",
	},
	socialContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "fit-content",
	},
	socialIcons: {
		marginLeft: "5px",
	},
	iconsMobile: {
		marginLeft: "15px",
	},
}));

const Topbar = () => {
	const classes = useStyle();
	const [popoverPhone, setPopoverPhone] = useState(null);
	const [popoverEmail, setPopoverEmail] = useState(null);

	const handleClickPhone = (event) => {
		setPopoverPhone(event.currentTarget);
	};

	const handleClosePhone = () => {
		setPopoverPhone(null);
	};

	const handleClickEmail = (event) => {
		setPopoverEmail(event.currentTarget);
	};

	const handleCloseEmail = () => {
		setPopoverEmail(null);
	};

	const openPhone = Boolean(popoverPhone);
	const idPhone = openPhone ? "simple-popover" : undefined;

	const openEmail = Boolean(popoverEmail);
	const idEmail = openEmail ? "simple-popover" : undefined;

	return (
		<Fragment>
			{/* Desk */}
			<Container maxWidth={false} className={classes.topBarDesk}>
				<Box className={classes.phoneContainer}>
					<PhoneIcon fontSize="medium" />
					<Typography
						className={classes.text}
						style={{ marginLeft: "1em" }}>
						Resistencia:
						<span style={{ fontWeight: "300", margin: "0px 5px" }}>
							(0362) 445-0222 / 154283839
						</span>
					</Typography>
					<Typography
						className={classes.text}
						style={{ marginLeft: "1em" }}>
						Saenz Peña:
						<span style={{ fontWeight: "300", margin: "0px 5px" }}>
							(0364) 442-2521 / 154698336
						</span>
					</Typography>
				</Box>
				<Box className={classes.socialContainer}>
					<Typography
						className={classes.text}
						style={{ marginLeft: "1em" }}>
						<a
							href="mailto:info@inmobiliariaecheverria.com"
							style={{
								textDecoration: "none",
								color: "rgba(255,255,255,0.6)",
							}}>
							info@inmobiliariaecheverria.com
						</a>
					</Typography>
					<a
						href="https://www.facebook.com/echeverriainmobiliaria/"
						rel="noreferrer"
						target="_blank"
						style={{
							display: "flex",
							textDecoration: "none",
							color: "inherit",
						}}>
						<FacebookIcon
							fontSize="medium"
							className={classes.socialIcons}
						/>
					</a>
					<a
						href="https://www.instagram.com/echeverria_inmobiliaria/"
						rel="noreferrer"
						target="_blank"
						style={{
							display: "flex",
							textDecoration: "none",
							color: "inherit",
						}}>
						<InstagramIcon
							fontSize="medium"
							className={classes.socialIcons}
						/>
					</a>
				</Box>
			</Container>

			{/* Mobile */}
			<Container maxWidth={false} className={classes.topBarMobile}>
				<Box className={classes.phoneContainer}>
					<PhoneIcon fontSize="medium" onClick={handleClickPhone} />
					<Popover
						id={idPhone}
						open={openPhone}
						anchorEl={popoverPhone}
						onClose={handleClosePhone}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}>
						<Typography className={classes.textMobile}>
							Resistencia:
							<span
								style={{
									fontWeight: "300",
									margin: "0px 5px",
								}}>
								(0362) 445-0222 / 154283839
							</span>
						</Typography>
						<Typography className={classes.textMobile}>
							Saenz Peña:
							<span
								style={{
									fontWeight: "300",
									margin: "0px 5px",
								}}>
								(0364) 442-2521 / 15999999
							</span>
						</Typography>
					</Popover>
				</Box>
				<Box className={classes.socialContainer}>
					<EmailIcon
						fontSize="medium"
						className={classes.iconsMobile}
						onClick={handleClickEmail}
					/>
					<Popover
						id={idEmail}
						open={openEmail}
						anchorEl={popoverEmail}
						onClose={handleCloseEmail}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "center",
						}}>
						<Typography className={classes.textMobile}>
							<a
								href="mailto:info@inmobiliariaecheverria.com"
								style={{
									textDecoration: "none",
									color: "#b5b3b1",
								}}>
								info@inmobiliariaecheverria.com
							</a>
						</Typography>
					</Popover>
					<a
						href="https://www.facebook.com/echeverriainmobiliaria/"
						rel="noreferrer"
						target="_blank"
						style={{
							display: "flex",
							textDecoration: "none",
							color: "inherit",
						}}>
						<FacebookIcon
							fontSize="medium"
							className={classes.iconsMobile}
						/>
					</a>
					<a
						href="https://www.instagram.com/echeverria_inmobiliaria/"
						rel="noreferrer"
						target="_blank"
						style={{
							display: "flex",
							textDecoration: "none",
							color: "inherit",
						}}>
						<InstagramIcon
							fontSize="medium"
							className={classes.iconsMobile}
						/>
					</a>
				</Box>
			</Container>
		</Fragment>
	);
};

export default Topbar;
