import React, { Fragment, useEffect, useState } from 'react'
import { makeStyles, Container, Box, Button, TextField } from '@material-ui/core'
import { useSelector } from 'react-redux'
import CodigosTipos from '../data/CodigosTipos.json'
import CodigosOperacion from '../data/CodigosOperaciones.json'
import CodigosCiudadesChaco from '../data/CodigosCiudadesChaco.json'
import CodigosCiudadesCorrientes from '../data/CodigosCiudadesCorrientes.json'

const useStyle = makeStyles((theme) => ({
    root: {
        margin: "2em",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column"
        }
    },
    selectors: {
        minWidth: "180px",
        margin: "0px 5px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "20px"
        },
        "& .MuiFormLabel-root": {
            color: "#626262"
        },
        "& .MuiInputBase-input": {
            color: "#626262",
            background: "white"
        }
    },
    boxSelectorsPrice: {
        display: "flex",
        flexDirection: "row"
    },
    selectorsPrice: {
        minWidth: "130px",
        maxWidth: "130px",
        margin: "0px 5px",
        background: "white",
        [theme.breakpoints.down('md')]: {
            marginBottom: "20px",
            minWidth: "45%",
            maxWidth: "50%",
        },
        "& .MuiFormLabel-root": {
            color: "#626262"
        }
    },
    searchButton: {
        "& .MuiButton-label": {
            margin: "0px 15px"
        }
    }
}))

const FiltersBar = (props) => {
    const classes = useStyle()
    const allPropierties = useSelector((state) => state.properties)

    const codTipo = CodigosTipos
    const codOperacion = CodigosOperacion
    const codChacoCiudades = CodigosCiudadesChaco
    const codCorrientesCiudades = CodigosCiudadesCorrientes

    const [counter, setCounter] = useState(0)

    const [selectorProvincia, setSelectorProvincia] = useState('')
    const [selectorCiudad, setSelectorCiudad] = useState('')
    const [selectorOperacion, setSelectorOperacion] = useState('')
    const [selectorTipo, setSelectorTipo] = useState('')
    const [selectorPrecioDesde, setSelectorPrecioDesde] = useState('')
    const [selectorPrecioHasta, setSelectorPrecioHasta] = useState('')
    
    const [search, setSearch] = useState('')

    const [operaciones, setOperaciones] = useState([])
    const [tipos, setTipos] = useState([])
    const [ciudades, setCiudades] = useState([])

    var newUrl

    var filtroCiudad = []
    var filtroOperacion = []
    var filtroTipo = []

    const getFiltros = () => {
        let o, t, uA, uB

        filtroCiudad = []
        filtroOperacion = []
        filtroTipo = []

        if(props.operacion==="empty" && allPropierties){
            o = allPropierties.Ofertas.map(e => e.Operacion)
            o = o.filter((x, i, a) => a.indexOf(x) === i)

            for (let index = 0; index < o.length; index++) {
                filtroOperacion.push(codOperacion.find(element => element.value === o[index]))
            }
        }else{
            filtroOperacion = []
        }

        if(props.tipo==="empty" && allPropierties){
            t = allPropierties.Ofertas.map(e => e.Producto)
            t = t.filter((x, i, a) => a.indexOf(x) === i)

            for (let index = 0; index < t.length; index++) {
                filtroTipo.push(codTipo.find(element => element.value === t[index]))
            }
        }else{
            filtroOperacion = []
        }

        if(props.ubicacion==="7" && allPropierties){
            uA = allPropierties.Ofertas.map(e => e.Ubicacion.split(",")[1].replace(" ", ""))
            uA = uA.filter((x, i, a) => a.indexOf(x) === i)

            for (let index = 0; index < uA.length; index++) {
                filtroCiudad.push(codCorrientesCiudades.find(element => element.value === uA[index]))
            }

            filtroCiudad = filtroCiudad.filter(Boolean)
        }else if(props.ubicacion==="384" && allPropierties){
            uB = allPropierties.Ofertas.map(e => e.Ubicacion.split(",")[1].replace(" ", ""))
            uB = uB.filter((x, i, a) => a.indexOf(x) === i)

            for (let index = 0; index < uB.length; index++) {
                filtroCiudad.push(codChacoCiudades.find(element => element.value === uB[index]))
            }

            filtroCiudad = filtroCiudad.filter(Boolean)
        }else{
            filtroCiudad = []
        }
        
        setOperaciones(filtroOperacion)
        setTipos(filtroTipo)
        setCiudades(filtroCiudad)
    }

    // Effect para obtener los filtros de búsqueda
    useEffect(() => {
        props.propiedades.length!==0?getFiltros():setTimeout(() => {
            setCounter(counter+1)
        }, 2000)
        // eslint-disable-next-line
    }, [counter, allPropierties])

    // Selectors
    const handleSelectorOperacion = (event) => {
        setSelectorOperacion(event.target.value)
    }

    const handleSelectorTipo = (event) => {
        setSelectorTipo(event.target.value)
    }

    const handleSelectorProvincia = (event) => {
        setSelectorProvincia(event.target.value)
    }

    const handleSelectorCiudad = (event) => {
        setSelectorCiudad(event.target.value)
    }

    const handleSelectorPrecioDesde = (event) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '')
        setSelectorPrecioDesde(onlyNums)
    }

    const handleSelectorPrecioHasta = (event) => {
        const onlyNums = event.target.value.replace(/[^0-9]/g, '')
        setSelectorPrecioHasta(onlyNums)
    }
    
    const handleSearch = () => {
        let desde = ''
        let hasta = ''

        if(selectorPrecioDesde!=='')desde = '&precioDesde=' + selectorPrecioDesde
        if(selectorPrecioHasta!=='')hasta = '&precioHasta=' + selectorPrecioHasta

        setSearch(selectorProvincia+selectorCiudad+selectorTipo+selectorOperacion+desde+hasta)
        //props.onHandleClose(false)
    }

    useEffect(() => {
        // eslint-disable-next-line
        newUrl = props.link + search

        props.onHandleSearch(newUrl)
        props.onHandleUbicacion(selectorProvincia)
    }, [search])

    useEffect(() => {
        props.propiedades.length!==0?getFiltros():console.log("Loanding filters...")
        // eslint-disable-next-line
    }, [props.ubicacion])

    return (
        <Fragment>
            <Container>
                <Box className={classes.root}>
                    {operaciones.length!==0 && props.operacion==="empty"?
                        <TextField className={classes.selectors} size="small" id="select-tipo-operacion" select label="Tipo de Operación" value={selectorOperacion} onChange={handleSelectorOperacion} SelectProps={{native: true}} variant="outlined">
                            <option key="0" value={""}></option>
                            {operaciones.length!==0 && operaciones.map((f) => (
                                <option key={f.id} value={"&idOperacion="+f.id}>
                                    {f.value}
                                </option>
                            ))}
                        </TextField>
                    :
                        <TextField className={classes.selectors} size="small" id="select-tipo-operacion" label={props.operacion} variant="outlined" disabled />
                    }
                    {tipos.length!==0 && props.tipo==="empty"?
                        <TextField className={classes.selectors} size="small" id="select-tipo-propiedad" select label="Tipo de Propiedad" value={selectorTipo} onChange={handleSelectorTipo} SelectProps={{native: true}} variant="outlined">
                            <option key="0" value={""}></option>
                            {tipos.length!==0 && tipos.map((f) => (
                                <option key={f.id} value={"&idProducto="+f.id}>
                                    {f.value}
                                </option>
                            ))}
                        </TextField>
                    :
                        <TextField className={classes.selectors} size="small" id="select-tipo-propiedad" label={props.tipo} variant="outlined" disabled />
                    }
                    {props.ubicacion==="empty"?
                        <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" select label="Provincia" value={selectorProvincia} onChange={handleSelectorProvincia} SelectProps={{native: true}} variant="outlined">
                            <option key="0" value={""}></option>
                            <option key="384" value={"&idProvincia=384"}>Chaco</option>
                            <option key="7" value={"&idProvincia=7"}>Corrientes</option>
                        </TextField>
                    :
                        <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" select label={props.ubicacion==="384"?"Chaco":"Corrientes"} value={selectorProvincia} onChange={handleSelectorProvincia} SelectProps={{native: true}} variant="outlined">
                            <option key="0" value={""}></option>
                            <option key="384" value={"&idProvincia=384"}>Chaco</option>
                            <option key="7" value={"&idProvincia=7"}>Corrientes</option>
                        </TextField>
                    }
                    {ciudades.length!==0 && props.propiedades.length!==0 && props.ubicacion!=="empty"?
                        <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" select label="Localidad" value={selectorCiudad} onChange={handleSelectorCiudad} SelectProps={{native: true}} variant="outlined">
                            <option key="0" value={""}></option>
                            {ciudades.length!==0 && ciudades.map((f) => (
                                <option key={f.id} value={"&idArea="+f.id}>
                                    {f.value}
                                </option>
                            ))}
                        </TextField>
                    :
                        <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" label="Localidad" variant="outlined" disabled />
                    }
                    <div className={classes.boxSelectorsPrice}>
                        <TextField className={classes.selectorsPrice} id="outlined-basic" label="Precio desde" variant="outlined" size="small" value={selectorPrecioDesde} onChange={handleSelectorPrecioDesde} />
                        <TextField className={classes.selectorsPrice} id="outlined-basic" label="Precio hasta" variant="outlined" size="small" value={selectorPrecioHasta} onChange={handleSelectorPrecioHasta} />
                    </div>
                    
                    <Button className={classes.searchButton} variant="contained" size="small" color="primary" onClick={handleSearch}>Buscar</Button>
                </Box>
            </Container>
        </Fragment>
    )
}

export default FiltersBar
