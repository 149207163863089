import React, { Fragment } from "react"
import { Link, NavLink } from 'react-router-dom'
import { makeStyles, Box, AppBar, Toolbar, Container } from "@material-ui/core"
import Logo from '../assets/images/Echeverria-Logo2.png'
import Topbar from "./Topbar"
import DrawerMenu from "./DrawerMenu"

const useStyle = makeStyles((theme) => ({
    navBar: {
        backgroundColor: "#fff",

        "& .MuiToolbar-regular" : {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: 80,
        }
    },
    imgContainer: {
        display: "flex",
        flexGrow: 1
    },
    imgStyle: {
        width: 160,
    },
    drawerMenuContainer: {
        [theme.breakpoints.down(843)]: {
            display: "block", 
        },
        [theme.breakpoints.up(843)]: {
            display: "none"
        }
    },
    menuDeskContainer: {
        [theme.breakpoints.down(843)]: {
            display: "none",
        },
        [theme.breakpoints.up(843)]: {
            display: "flex",
            width: "100%",
            justifyContent: "flex-end"
        },
        "& > li" : {
            paddingLeft: 45,
            listStyle: "none",
        },
        "& > li:nth-child(4) > span" : {
            color: theme.palette.text.disabled,
            cursor: "not-allowed",
        }
    },
    navLink : {
        color: theme.palette.text.primary,
        textDecoration: "none",
        textTransform: "uppercase",
        fontWeight: 500,
        lineHeight: 3,
        padding: "0 3px",
    },
    activeLink : {
        borderBottom: "2px solid",
        borderBottomColor: theme.palette.primary.main,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
    },
}))

const Navbar = () => {
    const classes = useStyle()

    return (
        <Fragment>
            <AppBar position="static" className={classes.navBar}>
                <Topbar />

                <Container style={{maxWidth: 1100, padding:0}}>
                    <Toolbar>
                        <Link style={{textDecoration: "none"}} to="/">
                            <Box className={classes.imgContainer}>
                                <img src={Logo} alt="Echeverria Inmobiliaria" className={classes.imgStyle} />
                            </Box>
                        </Link>
                        <ul className={classes.menuDeskContainer}>
                            <li>
                                <NavLink exact={true} className={classes.navLink} activeClassName={classes.activeLink} to="/">
                                    Buscador
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={classes.navLink} activeClassName={classes.activeLink} to="/alquilar">
                                    Alquilar
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={classes.navLink} activeClassName={classes.activeLink} to="/comprar">
                                    Comprar
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={classes.navLink} activeClassName={classes.activeLink} to="/tasaciones">
                                    Tasaciones
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className={classes.navLink} activeClassName={classes.activeLink} to="/contacto">
                                    Contacto
                                </NavLink>
                            </li>
                        </ul>
                        <div className={classes.drawerMenuContainer}>
                            <DrawerMenu />
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        </Fragment>
    )
}
 
export default Navbar