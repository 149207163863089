import React, { useState } from 'react'
import { makeStyles, Container, Box, TextField, Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import LinkProduct from './LinkProduct'

const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: "2em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "white",
        width: "fit-content",
        padding: "1.4em 4em",
        borderRadius: "30px"
    },
    titleSearcher: {
        fontWeight: "bold",
        fontSize: "1.5rem",
        marginBottom: "5px",
        [theme.breakpoints.down(600)]: {
            color: "#232323",
            textShadow: "0 0 10px rgba(255,255,255,1)"
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "1rem"
        },
    },
    containerSelector: {
        display: "flex",
        "& .MuiInputBase-root": {
            borderRadius: "30px",
            marginRight: "5px"
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "center"
        }
    },
    searchButton: {
        borderRadius: "30px",
        "& .MuiButton-label": {
            margin: "0px 30px"
        },
        [theme.breakpoints.down('md')]: {
            marginTop: "1em"
        }
    }
}))

const SearcherCode = () => {
    const classes = useStyle()
    const [codInmob, setCodInmob] = useState('')
    const [codOf, setCodOf] = useState('')

    const handleInput = (e) => {
        const str = e.target.value
        if(/^[0-9A-Z]+$/i.test(str) || e.target.value === ''){
            if(str.length > 5) {
                setCodInmob(str.slice(0,3))
                setCodOf(str.slice(3))
            }
        }
    }

    return (
        <Container maxWidth={false} className={classes.root}>
            <Typography align="center" color="textPrimary" className={classes.titleSearcher}>O ingresa el código de la oferta</Typography>
            <Box className={classes.containerSelector}>
                <Box>
                    <TextField id="outlined-basic" label="Código" variant="outlined" size="small" helperText="Código Inmobiliaria + Código Oferta (ej: ev1199)" onChange={handleInput}/>
                </Box>
                <Box>
                    <LinkProduct codOferta={codOf} codInmobiliaria={codInmob}>
                        <Button className={classes.searchButton} variant="contained" size="large" color="primary">Ir a la oferta</Button>
                    </LinkProduct>
                </Box>
            </Box>
        </Container>
    )
}

export default SearcherCode
