import React from 'react'
import Showcase from './Showcase'
import { makeStyles, Typography } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
    titleSearcher: {
        color: "#232323",
        fontWeight: "bold",
        fontSize: "2.5rem",
        [theme.breakpoints.down('md')]: {
            fontSize: "2rem"
        }
    }
}))

const Buy = () => {
    const classes = useStyle()

    const opParam = 'Venta'
    const tyParam = 'empty'
    const ubParam = 'empty'

    return (
        <div>
            <Typography align="center" className={classes.titleSearcher}>Ventas</Typography>
            <Showcase op={opParam} ty={tyParam} ub={ubParam} />
        </div>
    )
}

export default Buy