import React, { Fragment, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Skeleton, Pagination } from '@material-ui/lab'
import CodigosTipos from '../data/CodigosTipos.json'
import CodigosOperacion from '../data/CodigosOperaciones.json'
import ShowcaseCard from './ShowcaseCard'
import FiltersBar from './FiltersBar'
import FiltersBarMobile from './FiltersBarMobile'
import NotFound from './NotFound'

const useStyles = makeStyles((theme) => ({
	root: {
        display: "flex",
        justifyContent: "flex-start",
        margin: "auto",
        maxWidth: 960,
    },
    skeletonCard : {
        margin: "30px 0", 
        padding: "0 10px",
        [theme.breakpoints.down(600)] : {
            padding: "0 30px",
        }
    },
    filtersDesk: {
        display: "block !important",
        [theme.breakpoints.down('md')]: {
            display: "none !important"
        }
    },
    filtersMobile: {
        display: "none !important",
        [theme.breakpoints.down('md')]: {
            display: "block !important"
        }
    }
}))

const Showcase = (props) => {
    window.scrollTo(0, 0);
    const classes = useStyles()

    const codTipo = CodigosTipos
    const codOperacion = CodigosOperacion

    const [propiedades, setPropiedades] = useState([])

    const [idOperacion, setIdOperacion] = useState(null)
    const [idTipo, setIdTipo] = useState(null)

    const [loading, setLoading] = useState(true)
    const [parametros, setParametros] = useState(true)
    const [counter, setCounter] = useState(0)

    const [page, setPage] = useState(1)
    const [link, setLink] = useState('')
    const [ubParam, setUbParam] = useState("empty")

    var url

    const formatUrl = () => {
        if(props.op!=="empty"){
            setIdOperacion(codOperacion.find(element => element.value === props.op))
            idOperacion?url=url+"&idOperacion="+idOperacion.id:setParametros(!parametros)
        }else{setIdOperacion(props.op)}

        if(props.ty!=="empty"){
            const tyRegex = props.ty.replaceAll("-", "/")
            setIdTipo(codTipo.find(element => element.value === tyRegex))
            idTipo?url=url+"&idProducto="+idTipo.id:setParametros(!parametros)
        }else{setIdTipo(props.ty)}

        if(props.ub!=="empty"){
            setUbParam(props.ub)
            url = url+"&idProvincia="+props.ub
        }else{setUbParam(props.ub)}

        setLink(url)
    }

    const fetchData = () => {
        idOperacion!==null && idTipo!==null?
        fetch(url)
            .then(response => response.json())
            .then(data => setPropiedades(data), setLoading(false))
        :setCounter(counter+1)
    }

    const handleChange = (data, value) => {
        setPage(value)
    }

    const handleSearch = (event) => {
        url = event
        setLink(url)
        fetchData()
    }

    const handleUbicacion = (event) => {
        event==="&idProvincia=7"?setUbParam("7"):setUbParam("384")
    }

    // Effect que se ejecuta hasta tener el link para la llamada a la API
    useEffect(() => {
        // eslint-disable-next-line
        url = 'https://api.topinmobiliario.com/buscadorinmobiliaria.php?usuario=EV1&clave=chascomus&idEstadoOferta=1&cantidad=12&pagina='+page

        formatUrl()
        idOperacion!==null && idTipo!==null?fetchData():setParametros(!parametros)
        // eslint-disable-next-line
    }, [ parametros ])

    // Effect para llamar a la API
    // useEffect(() => {
    //     // eslint-disable-next-line
    //     url = 'https://api.topinmobiliario.com/buscadorinmobiliaria.php?usuario=EV1&clave=chascomus&idEstadoOferta=1&cantidad=12&pagina='+page

    //     fetchData()
    //     // eslint-disable-next-line
    // }, [ counter ])

    // Effect para llamar a la API por cada página
    useEffect(() => {
        // eslint-disable-next-line
        url = link
        // eslint-disable-next-line
        let regex = /\&pagina=\d/i
        url = url.replace(regex, '&pagina='+page)
        fetchData()
        // eslint-disable-next-line
    }, [page])
        
    var skeleton = [];
    const isMobile = (window.innerWidth <= 600) ? true : false
    for (var i = 0; i < (isMobile?4:6); i++) {
        skeleton.push(
                <Grid item xs={12} sm={6} md={4} className={classes.skeletonCard} key={i}>
                    <Skeleton animation="wave" variant="rect" height={200} width="100%" style={{marginBottom: "5px"}}/>
                    <Skeleton animation="wave" height={25} width="100%" />
                    <Skeleton animation="wave" height={25} width="90%" />
                    <Skeleton animation="wave" height={60} width="60%" />

                    <Skeleton animation="wave" height={25} width="70%" />
                    <Skeleton animation="wave" height={25} width="70%" />

                    {/* <div style={{display: "flex"}}>
                        <Skeleton animation="wave" height={50} width="25%" style={{marginRight: "5px"}}/>
                        <Skeleton animation="wave" height={50} width="25%" style={{margin: "0 5px"}}/>
                        <Skeleton animation="wave" height={50} width="25%" style={{margin: "0 5px"}}/>
                        <Skeleton animation="wave" height={50} width="25%" style={{marginLeft: "5px"}}/>
                    </div> */}
                </Grid>
        );
    }

    return (
        <Fragment>
            <div className={classes.filtersDesk}>
                <FiltersBar link={link} ubicacion={ubParam} operacion={props.op} tipo={props.ty} propiedades={propiedades} onHandleSearch={handleSearch} onHandleUbicacion={handleUbicacion} />
            </div>
            <Grid container className={classes.root}>
                {(!loading && propiedades.length!==0)
                    ? propiedades.Ofertas.length!==0?propiedades.Ofertas.map((oferta) => (
                        <ShowcaseCard key={oferta.CodOferta} codOferta={oferta.CodOferta} codInmobiliaria={oferta.CodInmobiliaria} titulo={oferta.Titulo} operacion={oferta.Operacion} direccion={oferta.Direccion} ubicacion={oferta.Ubicacion} moneda={oferta.Moneda} importe={oferta.Importe} img={oferta.Foto} />
                    )):<NotFound />
                    : skeleton
                }
            </Grid>
            <div style={{display: "flex", justifyContent: "center"}}>
                <Pagination style={{margin: "1em"}} count={propiedades.CantidadPaginas} siblingCount={1} color="primary" onChange={handleChange} page={page} size="small" />
            </div>
            <div className={classes.filtersMobile}>
                <FiltersBarMobile link={link} ubicacion={ubParam} operacion={props.op} tipo={props.ty} propiedades={propiedades} onHandleSearch={handleSearch} onHandleUbicacion={handleUbicacion} />
            </div>
        </Fragment>
    )
}

export default Showcase
