import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import ContactForm from "./ContactForm";
import Logo from "../assets/images/Echeverria-Logo2.png";

const useStyles = makeStyles((theme) => ({
	root: {
		color: "#666666",
		"& h6": {
			[theme.breakpoints.between(600, 810)]: {
				fontSize: 15,
			},
		},
		"& p": {
			[theme.breakpoints.down(600)]: {
				fontSize: 15,
			},
			[theme.breakpoints.between(600, 810)]: {
				fontSize: 10,
			},
		},
	},
	mainContainer: {
		margin: "1em auto",
		justifyContent: "center",
		[theme.breakpoints.down(875)]: {
			maxWidth: "95%",
		},
		[theme.breakpoints.between(875, 1024)]: {
			maxWidth: "90%",
		},
		[theme.breakpoints.up(1024)]: {
			maxWidth: 1024,
		},
	},
	container: {
		background: "white",
		padding: "1.5em",
		marginBottom: "1.5em",
		borderRadius: 30,
	},
	infoContainer: {
		padding: "0 .5em",
		[theme.breakpoints.down("md")]: {
			padding: 0,
		},
	},
	textOverflow: {
		width: "auto",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"&:hover": {
			whiteSpace: "normal",
			overflow: "visible",
		},
	},
	h6: {
		margin: "1em 0",
	},
}));

export default function Contact() {
	window.scrollTo(0, 0);
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Grid container spacing={3} className={classes.mainContainer}>
				<Grid item sm={8}>
					<div className={classes.container}>
						<Typography
							variant="h6"
							align="justify"
							style={{ marginBottom: "1em" }}>
							Tasaciones
						</Typography>
						<Typography
							variant="h7"
							align="justify"
							style={{ marginBottom: "1em" }}>
							Mediante un informe detallado de tasación Ud. puede
							conocer el valor de su propiedad y tomar mejores
							decisiones, ya sea al vender o alquilar su inmueble.
							<br />
							Un informe de tasación profesional es también
							imprescindible para división de patrimonio,
							sucesorios y balances comerciales.
						</Typography>
					</div>

					<div className={classes.container}>
						<Typography variant="h6" align="justify">
							Juntos podemos encontrar el lugar ideal. Contanos en
							qué podemos ayudarte.
						</Typography>
						<ContactForm asunto={"Tasaciones"} />
					</div>
				</Grid>

				<Grid
					item
					sm={4}
					className={classes.fontSizes}
					style={{ width: "100%" }}>
					<div className={classes.container}>
						<img
							src={Logo}
							width="100%"
							alt="Echeverria Logo"
							className={classes.image}
						/>

						<Typography variant="h6" style={{ marginTop: "1em" }}>
							Contacto
						</Typography>
						<p>
							<b>Email</b>
						</p>
						<p className={classes.textOverflow}>
							info@inmobiliariaecheverria.com
						</p>

						<p>
							<b>Teléfono</b>
						</p>
						<p className={classes.textOverflow}>
							Resistencia: (0362) 4450222 / 154283839
						</p>
						<p className={classes.textOverflow}>
							Sáenz Peña: (0364) 4422521 / 154698336
						</p>

						<p>
							<b>Dirección</b>
						</p>
						<p>Resistencia: Av. Sarmiento 906</p>
						<p>Sáenz Peña: M. Moreno 854</p>
					</div>
					{/* <div className={classes.container}>
						<Typography variant="h6">Horarios de atención</Typography>
						<p><b>Lunes a viernes</b></p>
						<p><b>Mañana:</b> 8 a.m. to 12 p.m.</p>
						<p><b>Tarde:</b> 16 p.m a 20 p.m.</p>
					</div> */}
				</Grid>
			</Grid>
		</div>
	);
}
