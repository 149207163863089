import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getEstatesAsync = createAsyncThunk(
    'properties/getEstatesAsync',
    async() => {
        const response = await fetch (`${window.location.protocol}//api.topinmobiliario.com/buscadorinmobiliaria.php?usuario=EV1&clave=chascomus&idEstadoOferta=1`)
        if(response.ok){
            const properties = await response.json()
            return { properties }
        }
    }
)

const estateSlice = createSlice({
    name: "properties",
    initialState: null,
    extraReducers: {
        [getEstatesAsync.pending]: (state, action) => {
            console.log("Getting data...")
        },
        [getEstatesAsync.fulfilled]: (state, action) => {
            console.log("Getting data successfully!")
            // console.log(action.payload.properties)
            return action.payload.properties
        }
    }
})

// export const { showAll } = estateSlice.actions
export default estateSlice.reducer