import { compose, withProps, withStateHandlers } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"
import { Skeleton } from '@material-ui/lab'


const ProductMap = compose(
	withStateHandlers(() => ({
        isPMarkerOpen: true,
    }), {
        onTogglePMarkerOpen: ({ isPMarkerOpen }) => () => ({
        isPMarkerOpen: !isPMarkerOpen,
        }),
    }),
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAH03rd1TTS7zcPBg-jZaw39JYcF7yJe_8&libraries=geometry,drawing,places`,
        loadingElement: <Skeleton animation="wave" variant="rect" height={400} width="100%" />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
    )((props) => {
        const isEmptyCoords = props.lat === 0 || props.lng === 0
        var zoom = 7
        var lat = -27.443899524389607
        var lng = -58.978269868189685

        if (!isEmptyCoords) {
            zoom = 15
            lat = (props.lat)+0.002
            lng = props.lng
	}

  return(
		<GoogleMap
			defaultZoom={zoom}
			defaultCenter={{lat, lng}}
			center={{lat,lng}}
		>	
			<Marker
				position={{ lat: -27.443899524389607, lng: -58.978269868189685 }}
				icon="/images/markerEcheverria.png"
			>
				{/* <InfoWindow>
					<div style={{ backgroundColor: "#00873A", opacity: 0.75, padding: `12px` }}>
						<div style={{ backgroundColor: "#55AE32", fontSize: `16px`, fontColor: `#08233B` }}>
							Hello, Taipei!
						</div>
					</div>
				</InfoWindow> */}
			</Marker>
			<Marker
				position={{ lat: -26.78563357409438, lng: -60.44081714613091 }}
				icon="/images/markerEcheverria.png"
			>
				{/* <InfoWindow>
					<div style={{ backgroundColor: "#00873A", opacity: 0.75, padding: `12px` }}>
						<div style={{ backgroundColor: "#55AE32", fontSize: `16px`, fontColor: `#08233B` }}>
							Hello, Taipei!
						</div>
					</div>
				</InfoWindow> */}
			</Marker>

			{(!isEmptyCoords) && 
				<Marker
					position={{ lat: (props.lat), lng: props.lng }}
					icon="/images/markerPropiedad.png"
					onClick={props.onTogglePMarkerOpen}
				>
					{props.isPMarkerOpen && <InfoWindow onCloseClick={props.onTogglePMarkerOpen}>
						<span style={{  fontSize: "16px", color: "white" }}>
							<b>Esta propiedad</b>
						</span>
						{/*<span style={{textTransform: "uppercase"}}>{props.tipoOp}</span>
						 <span>{props.direc}</span>
						<span>{props.ciudad}</span>
						<span>{props.prov}</span>
						<span>{props.pais}</span> */}
					</InfoWindow>}
				</Marker>
			}
		</GoogleMap>
	)
}
);

export default ProductMap
