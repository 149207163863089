import React, { Fragment } from "react"
import { Link } from 'react-router-dom'
import { makeStyles, Box, Typography, Button } from "@material-ui/core"
import Searcher from "./Searcher"
import FeaturedDeals from "./FeaturedDeals"

const useStyle = makeStyles((theme) => ({
    bgBanner : {
        backgroundImage: 'url("images/bg-img5.png")',
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    },
    box: {
        display: "flex",
        justifyContent: "center",
        padding: "5em 2.5em",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column"
        },
        [theme.breakpoints.up(600)]: {
            flexDirection: "row"
        },
    },
    textContainer: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: "55%"
        },
        [theme.breakpoints.between('md', 'lg')]: {
            width: "40%"
        },
        [theme.breakpoints.up('lg')]: {
            width: "30%"
        }
    },
    text: {
        fontStyle: "italic",
        fontSize: "2em",
        color: "#fff"
    },
    buttonContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: "2em",
        [theme.breakpoints.down('md')]: {
            marginLeft: "0em",
            marginTop: "1em",
            justifyContent: "center"
        }
    },
    contactButton: {
        borderRadius: "30px",
        color: "white"
    }
}))

const Home = () => {
    const classes = useStyle()

    return (
        <Fragment>
            <Searcher />

            <FeaturedDeals />

            <div className={classes.bgBanner}>
                <Box className={classes.box} style={{backgroundColor:"rgba(0, 135, 58, 0.9)"}}>
                    <Box className={classes.textContainer}>
                        <Typography align="left" className={classes.text}>
                            ¿Estás buscando
                        </Typography>
                        <div style={{display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
                            <Typography align="center" variant="h3" style={{fontWeight: 500, color: "white"}}>
                                Alquilar
                            </Typography>
                            <span className={classes.text}> o </span>
                            <Typography align="center" variant="h3" style={{fontWeight: 500, color: "white"}}>
                                Vender
                            </Typography>
                        </div>
                        <Typography align="right" className={classes.text}>
                            tu propiedad?
                        </Typography>
                    </Box>
                    <Box className={classes.buttonContainer}>
                    <Link to={"/contacto"} onClick={() => window.scrollTo(0, 0)} style={{textDecoration: "none"}}>
                        <Button className={classes.contactButton} variant="contained" size="medium" color="secondary">Contactanos</Button>
                    </Link>
                    </Box>
                </Box>
            </div>
            
        </Fragment>
    )
}
 
export default Home