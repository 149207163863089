import React, { useEffect, useState } from 'react'
import { makeStyles, Container, Box, TextField, Button, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SearcherCode from './SearcherCode'
import CodigosCiudadesChaco from '../data/CodigosCiudadesChaco.json'
import CodigosCiudadesCorrientes from '../data/CodigosCiudadesCorrientes.json'

const useStyle = makeStyles((theme) => ({
    searcher: {
        backgroundImage: 'url("images/bg-img1.jpg")',
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: "2em",
        height: 400,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.between(600, 'md')]: {
            backgroundPosition: "20% 0",
            height: "60vh",
        },
        [theme.breakpoints.down(600)]: {
            backgroundPosition: "20% 0",
            height: "100vh",
        }
    },
    titleSearcher: {
        fontWeight: "bold",
        fontSize: "2.5rem",
        marginBottom: "-1em",
        [theme.breakpoints.down(600)]: {
            color: "#232323",
            textShadow: "0 0 10px rgba(255,255,255,1)"
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "2rem"
        },
    },
    form: {
        display: "flex",
        justifyContent: "center"
    },
    formContainer: {
        display: "flex",
        alignItems: "center",
        borderRadius: "3em",
        backgroundColor: "#fff",
        width: "fit-content",
        padding: "3em 6em",
        margin: "5em 0 0",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            padding: "3em 1.7em"
        },
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: "unset",
            padding: "3em 2.4em"
        }
    },
    selectors: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "30px !important",
            margin: "0px 6px"
        },
        "& .MuiInputLabel-outlined": {
            marginLeft: "10px"
        },
        "& select": {
            color: "#232323",
            width: "220px"
        },
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            borderRadius: "30px"
        },
        "& label": {
            color: "#9d9b99"
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: "20px"
        }
    },
    searchButton: {
        borderRadius: "30px",
        "& .MuiButton-label": {
            margin: "0px 30px"
        }
    }
}))

const Searcher = () => {
    const classes = useStyle()
    var propiedades = useSelector((state) => state.properties)
    const codChacoCiudades = CodigosCiudadesChaco
    const codCorrientesCiudades = CodigosCiudadesCorrientes
    
    const [operacion, setOperacion] = useState([])
    const [tipo, setTipo] = useState([])
    const [ubicacion, setUbicacion] = useState([])
    const [localidad, setLocalidad] = useState([])

    const [selectOperacion, setSelectOperacion] = useState('empty')
    const [selectTipo, setSelectTipo] = useState('empty')
    const [selectUbicacion, setSelectUbicacion] = useState('empty')
    const [selectLocalidad, setSelectLocalidad] = useState('empty')

    var selectTipoRegex

    const ubicaciones = [
        {
            value: '384',
            label: 'Chaco'
        },
        {
            value: '7',
            label: 'Corrientes'
        }
    ]

    useEffect(() => {
        if(propiedades){
            const op = propiedades.Ofertas.map(e => e.Operacion)
            setOperacion(op.filter((x, i, a) => a.indexOf(x) === i))

            const pr = propiedades.Ofertas.map(e => e.Producto)
            setTipo(pr.filter((x, i, a) => a.indexOf(x) === i))

            setUbicacion(ubicaciones)
        }

	}, [ propiedades ]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let LocalidadesTemp
        var Localidades = []

        if(selectUbicacion==="7"){
            LocalidadesTemp = propiedades.Ofertas.map(e => e.Ubicacion.split(",")[1].replace(" ", ""))
            LocalidadesTemp = LocalidadesTemp.filter((x, i, a) => a.indexOf(x) === i)

            for (let index = 0; index < LocalidadesTemp.length; index++) {
                Localidades.push(codCorrientesCiudades.find(element => element.value === LocalidadesTemp[index]))
            }

            Localidades = Localidades.filter(Boolean)
        }else if(selectUbicacion==="384"){
            LocalidadesTemp = propiedades.Ofertas.map(e => e.Ubicacion.split(",")[1].replace(" ", ""))
            LocalidadesTemp = LocalidadesTemp.filter((x, i, a) => a.indexOf(x) === i)

            for (let index = 0; index < LocalidadesTemp.length; index++) {
                Localidades.push(codChacoCiudades.find(element => element.value === LocalidadesTemp[index]))
            }

            Localidades = Localidades.filter(Boolean)
        }

        setLocalidad(Localidades)
    }, [selectUbicacion])
    

    const handleChangeOperacion = (event) => {
        setSelectOperacion(event.target.value)
    }

    const handleChangeTipo = (event) => {
        setSelectTipo(event.target.value)
    }

    const handleChangeUbicacion = (event) => {
        setSelectUbicacion(event.target.value)
    }

    const handleChangeLocalidad = (event) => {
        setSelectLocalidad(event.target.value)
    }

    return (
        <Container maxWidth={false} className={classes.searcher}>
            <Typography align="center" color="textPrimary" className={classes.titleSearcher}>Encontrá tu lugar</Typography>
            <form className={classes.root+' '+classes.form} noValidate autoComplete="off">
                <Box className={classes.formContainer}>
                    <Box>
                        {operacion.length!==0?
                            <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" select label="Tipo de Operación" value={selectOperacion} onChange={handleChangeOperacion} SelectProps={{native: true}} variant="outlined">
                                    <option key="empty" value={"empty"}> </option>
                                {operacion.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                            :
                            <TextField className={classes.selectors} size="small" id="select-tipo-operacion" select label="Cargando..." variant="outlined" value={selectOperacion} SelectProps={{native: true}}>
                                <option key="0" value=""></option>
                            </TextField>
                        }
                    </Box>
                    <Box>
                        {tipo.length!==0?
                            <TextField className={classes.selectors} size="small" id="select-tipo" select label="Tipo de Propiedad" value={selectTipo} onChange={handleChangeTipo} SelectProps={{native: true}} variant="outlined">
                                    <option key="empty" value={"empty"}> </option>
                                {tipo.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </TextField>
                            :
                            <TextField className={classes.selectors} size="small" id="select-tipo" select label="Cargando..." variant="outlined" value={selectTipo} SelectProps={{native: true}}>
                                <option key="0" value=""></option>
                            </TextField>
                        }
                    </Box>
                    <Box>
                        {ubicacion.length!==0?
                            <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" select label="Ubicación" value={selectUbicacion} onChange={handleChangeUbicacion} SelectProps={{native: true}} variant="outlined">
                                    <option key="empty" value={"empty"}> </option>
                                {ubicacion.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            :
                            <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" select label="Cargando..." variant="outlined" value={selectUbicacion} SelectProps={{native: true}}>
                                <option key="0" value=""></option>
                            </TextField>
                        }
                    </Box>
                    <Box>
                        {localidad.length!==0 && selectUbicacion !== "empty" ?
                            <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" select label="Localidad" value={selectLocalidad} onChange={handleChangeLocalidad} SelectProps={{native: true}} variant="outlined">
                                <option key="0" value={""}></option>
                                {localidad.length!==0 && localidad.map((f) => (
                                    <option key={f.id} value={"&idArea="+f.id}>
                                        {f.value}
                                    </option>
                                ))}
                            </TextField>
                        :
                            <TextField className={classes.selectors} size="small" id="select-tipo-ubicacion" label="Localidad" variant="outlined" disabled />
                        }
                    </Box>
                    <Box>
                        <span style={{display: "none"}}>{selectTipoRegex = selectTipo.replaceAll("/","-")}</span>
                        <Link to={`/search-results/${selectOperacion},${selectTipoRegex},${selectUbicacion},${selectLocalidad}`} style={{textDecoration: "none"}}>
                            <Button className={classes.searchButton} variant="contained" size="large" color="primary">Buscar</Button>
                        </Link>
                    </Box>
                </Box>
            </form>

            <SearcherCode />

        </Container>
    )
}

export default Searcher