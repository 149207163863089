import React from 'react'
import { Link } from 'react-router-dom'

export default function LinkProduct(props) {
	return(
		<Link to={`/product/${props.codOferta},${props.codInmobiliaria}`} style={{textDecoration: "none", color:"inherit"}}>
			{props.children}
		</Link>
	)
}
