import React, { Fragment, useState } from 'react'
import { makeStyles, AppBar, Toolbar, Drawer, Typography } from '@material-ui/core'
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'
import FiltersBar from './FiltersBar'

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: 0,
    },
    filterButton: {
        display: "flex",
        justifyContent: "center"
    },
  }))

const FiltersBarMobile = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

    const handleSearch = (event) => {
        props.onHandleSearch(event)
    }

    const handleUbicacion = (event) => {
        props.onHandleUbicacion(event)
    }

    const handleClose = (event) => {
        setOpen(event)
    }

    return (
        <Fragment>
            <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} disableBackdropTransition={!iOS} disableDiscovery={iOS}>
                <div className={classes.list} role="presentation">
                    <FiltersBar link={props.link} ubicacion={props.ubicacion} operacion={props.operacion} tipo={props.tipo} propiedades={props.propiedades} onHandleSearch={handleSearch} onHandleUbicacion={handleUbicacion} onHandleClose={handleClose} />
                </div>
            </Drawer>
            <AppBar position="fixed" color="primary" className={classes.appBar}>
                <Toolbar className={classes.filterButton} onClick={() => setOpen(true)}>
                        <FilterListRoundedIcon fontSize="large" />
                        <Typography>FILTROS</Typography>
                </Toolbar>
            </AppBar>
        </Fragment>
    )
}

export default FiltersBarMobile
