import React, { useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Zoom } from "swiper"
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Thumbs, Zoom]);

const useStyles = makeStyles((theme) => ({
	productSwiper: {
    "& .swiper-button-prev, .swiper-button-next" : {
      color: "white !important",
      height: 40,
      width: 40,
			transform: "none !important"
    },
    "& .swiper-button-prev" : {
      content: 'url("https://img.icons8.com/ios-glyphs/30/ffffff/circled-chevron-left.png")'
    },
    "& .swiper-button-next" : {
      content: 'url("https://img.icons8.com/ios-glyphs/30/ffffff/circled-chevron-right.png")'
    },
  },
}));

export default function ProductSwiper(props) {
  const classes = useStyles()
  
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  
  var i = 0

  var j = 0
  props.fotos && props.fotos.map(e => (
    j++
  ))

  return (
    <>
      <Swiper 
        loop={true}
        spaceBetween={100} 
        navigation={true}
        zoom={true}
        autoHeight={true}
        centeredSlides={true}
        thumbs={{ swiper: thumbsSwiper }} 
        className={`productSwiper ${classes.productSwiper}`}>
        {props.fotos && props.fotos.map(e => (
          <SwiperSlide key={i}>
            <div className="swiper-zoom-container">
              <img src={e.replace('http:', 'https:')} alt={`Foto de Propiedad ${++i}`} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper 
        onSwiper={setThumbsSwiper} 
        loop={true} 
        spaceBetween={10} 
        slidesPerView={j>=4 ? 4 : j}
        freeMode={true}
        watchSlidesProgress={true} 
        className="productSwiperThumbs">
        {props.fotos && props.fotos.map(e => (
          <SwiperSlide key={i}><img src={e} alt={`Foto de Propiedad ${++i}`} /></SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
