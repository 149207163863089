import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root : {
		display: "flex",
		width: "100%",
		alignItems: "center",
		minHeight: "50vh",
    justifyContent: "center"
	},
}));

export default function NotFound() {
	const classes = useStyles()

	return(
		<div className={classes.root}>
			<Typography variant="h4" align="center">
				<b>No se encontraron resultados!</b>
			</Typography>
		</div>
	)
}
