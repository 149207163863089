import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { AccordionDetails,	Typography } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
	root : {
		width: '100%',
	},
	heading : {
    fontWeight: "700 !important",
		[theme.breakpoints.down('xs')]: {
			fontSize: "13px !important"
		},
  },
	body : {
		[theme.breakpoints.down('xs')]: {
			fontSize: "13px !important"
		},
	},
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
		marginBottom: "5px",
    color: "#666666",
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: '#f9f9f9',
    minHeight: 20,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {	},
}))(MuiAccordionSummary)

export default function FunctionsAccordion() {
	const classes = useStyles()
	const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

	return (
		<div className={classes.root}>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Tasaciones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" className={classes.body}>
            Mediante un informe detallado de tasación Ud. puede conocer el valor de
            su propiedad y tomar mejores decisiones, ya sea al vender o alquilar su
            inmueble.
            Un informe de tasación profesional es también imprescindible para
            división de patrimonio, sucesorios y balances comerciales.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}>Asesoramiento Legal</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" className={classes.body}>
                Nuestro equipo interdisciplinario cuenta con un abogado especialista en
                derecho inmobiliario para asesorarlo.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}>Asesoramiento Financiero</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" className={classes.body}>
            Nuestro equipo cuenta con contadores especializados en flujo de fondos
            para determinar rentabilidad de los desarrollos inmobiliarios.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}>Administración de propiedades</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" className={classes.body}>
            Contamos con una administración joven y flexible, preparados para dar
            solución integral en el mundo de los alquileres, sea usted locador o
            locatario.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.heading}>Desarrollo de proyectos inmobiliarios</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" className={classes.body}>
            Poseemos un equipo técnico de diseño urbano, con amplia capacidad de
            gestión  en el sector público y privado, asegurando así un producto
            exitoso en un lapso de tiempo inmejorable.
          </Typography>
        </AccordionDetails>
      </Accordion>

    </div>
	)
};
