import { createTheme } from "@material-ui/core"

const customTheme = createTheme({
	palette: {
		primary: {
			light: "#55AE32",
			main: "#00873A",
			dark: "",
			contrastText: ""
		},
    secondary: {
			light: "",
			main: "#EC8C10",
			dark: "",
			contrastText: ""
		},
		text: {
			primary: "rgba(109, 109, 109, 1)",
			secondary: "rgba(51, 61, 52, 1)",
			disabled: "rgba(0, 0, 0, 0.38)",
			hint: "rgba(0, 0, 0, 0.38)"
		},
		background: {
			default: "#f3f4f4"
		},
	},
	typography: {
		fontFamily: [
			'"Fira Sans"',
			'Helvetica',
			'Arial',
			'sans-serif',
		].join(','),
		h1: {
			fontFamily: "'Fira Sans','Helvetica','Arial',sans-serif"
		},
		h2: {
			fontFamily: "'Fira Sans','Helvetica','Arial',sans-serif"
		},
		h3: {
			fontFamily: "'Fira Sans','Helvetica','Arial',sans-serif"
		},
		h4: {
			fontFamily: "'Fira Sans','Helvetica','Arial',sans-serif"
		},
		h5: {
			fontFamily: "'Fira Sans','Helvetica','Arial',sans-serif"
		},
		h6: {
			fontFamily: "'Fira Sans','Helvetica','Arial',sans-serif"
		},
    body1: {
      fontFamily: "'Fira Sans','Helvetica','Arial',sans-serif"
    },
    body2: {
      fontFamily: "'Fira Sans','Helvetica','Arial',sans-serif"
    }
  }
})

export default customTheme