import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getEstatesAsync } from './redux/estateSlice'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import customTheme from './themeConfig'
import Navbar from "./components/Navbar"
import Home from "./components/Home"
import Rent from './components/Rent'
import Buy from './components/Buy'
import Tasaciones from './components/Tasaciones'
import Contact from "./components/Contact"
import SearchResults from './components/SearchResults'
import Product from './components/Product'
import Footer from "./components/Footer"
import WhatsAppFAB from './components/WhatsAppFAB/WhatsAppFAB'

function App() {
    const dispatch = useDispatch()

	useEffect(() => {
		dispatch(getEstatesAsync())
	}, [dispatch])

    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <div>
                <Router>
                    <Navbar />
                    <Switch>
                        <Route exact path='/' component={ Home } />
                        <Route exact path='/alquilar' component={ Rent } />
                        <Route exact path='/comprar' component={ Buy } />
                        <Route exact path='/tasaciones' component={ Tasaciones } />
                        <Route exact path='/contacto' component={ Contact } />
                        <Route exact path='/search-results/:opParam,:tyParam,:ubParam' component={ SearchResults } />
                        <Route exact path='/product/:codOferta,:codInmobiliaria' component={ Product } />
                    </Switch>
                    <WhatsAppFAB />
                    <Footer />
                </Router>
            </div>
        </ThemeProvider>
    )
}

export default App