import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	root : {
		textTransform: "uppercase",
		marginRight: "5px",
	},
	tag : {
		"&normal" : {
			borderRadius: "30px",
			padding: "0 .7em",
			border: "1px #6d6d6d solid",
			background: "white",
		},
		"&Venta" : {
			borderRadius: "30px",
			padding: ".1em .7em 0",
			background: theme.palette.error.main,
			color: "white",
		},
		"&Alquiler" : {
			borderRadius: "30px",
			padding: ".1em .7em 0",
			background: theme.palette.warning.main,
			color: "white",
		},
	},
	locationIcon : {
		color: "black",
	},
}))

export default function ProductTag(props) {
	const classes = useStyles()
	
	return (
		<div className={classes.root}>
		
			<Typography variant="subtitle2" align="center" color="textPrimary" className={`${classes.tag}${props.tag}`}>
				{props.title}
			</Typography>

		</div>
	)
};
