import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper"
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation,Pagination]);

const useStyles = makeStyles((theme) => ({
	gallerySwiper: {
    margin: 0,
    padding: 0,
		minHeight: "199px !important",
    maxHeight: "199px !important",
    boxSizing: "border-box",
    "& .swiper-slide" : {
      height: "199px !important",
      display: "flex",
      justifyContent: "center",
			width: "100% !important",
    },
    "& .swiper-button-prev, .swiper-button-next" : {
      color: "white !important",
      height: "30px !important",
      width: "30px !important",
			transform: "none !important"
    },
    "& .swiper-button-prev" : {
      content: 'url("https://img.icons8.com/ios-glyphs/30/ffffff/circled-chevron-left.png")'
    },
    "& .swiper-button-next" : {
      content: 'url("https://img.icons8.com/ios-glyphs/30/ffffff/circled-chevron-right.png")'
    },
    "& .swiper-pagination-bullet" : {
      background: "rgba(255,255,255,0.4) !important",
			width: "8px !important",
			height: "8px !important",
			display: "inline-block !important"

    },
    "& .swiper-pagination-bullet-active" : {
      background: "white !important",
    },
  },
  "& .swiper-slide img" : {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover !important",
  },
}));

export default function FeaturedCardGallery(props) {
  const classes = useStyles()

  var rows = [];
  for (var i = 0; i < 5; i++) {
    rows.push(<SwiperSlide key={i}><img src="/images/card-img.png" alt="" /></SwiperSlide>);
  }

  return (
    <>
      <Swiper 
        pagination={{
          "clickable": true,
          "dynamicBullets": true,
          "dynamicMainBullets": 3,
        }}
				slidesPerView={1}
        autoHeight={false}
				spaceBetween={40}
        navigation={true}
        simulateTouch={false}
        className={classes.gallerySwiper}>

        {props.images && props.images.map(e => (
          <SwiperSlide key={e}>
            <img style={{height:"199px !important"}} src={e.replace('http:', 'https:')} alt="" />
          </SwiperSlide>
        ))}

      </Swiper>
    </>
  )
}
