import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import FeaturedSwiper from './FeaturedSwiper'

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 905,
		margin: "5em auto",
  },
	sectionTitle : {
		[theme.breakpoints.down(930)] : {
			paddingLeft: 15
		}
	},
	kicker : {
		paddingLeft: 3,
		marginBottom: -5,
		color: theme.palette.primary.light,
		fontWeight: 700,
	},
	title : {
		fontWeight: 500,
	},
	divider : {
		height: 2,
		width: 30,
		marginTop: "1em",
		background: "black",
	}
}));

export default function FeaturedDeals() {
	const classes = useStyles()
	
	return (
		<div className={classes.root}>

			<div className={classes.sectionTitle}>
				<Typography variant="subtitle2" className={classes.kicker}>
					DESTACADOS PARA VOS
				</Typography>

				<Typography variant="h4" color="textSecondary" className={classes.title}>
					OPORTUNIDADES
				</Typography>

				<div className={classes.divider} /> 

			</div>

			<FeaturedSwiper />

		</div>
	)	
};
