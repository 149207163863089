import React from 'react'
import { useParams } from 'react-router'
import Showcase from './Showcase'

const SearchResults = () => {
    const { opParam, tyParam, ubParam} = useParams()

    return (
        <div>
            <Showcase op={opParam} ty={tyParam} ub={ubParam} />
        </div>
    )
}

export default SearchResults
