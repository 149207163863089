import React, { useState } from "react"
import "./WhatsAppFAB.scss"

export default function WhatsAppFAB() {
	const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

	return(
		<>
			<div className={`wa-button ${isActive ? 'open': ''}`} onClick={toggleClass} />
			<a href="https://wa.me/+5493624283839" rel="noreferrer" target="_blank" className={`localidades-button resis-button ${isActive ? 'active': ''}`}>
				Resistencia, Chaco
			</a>
			<a href="https://wa.me/+5493644698336" rel="noreferrer" target="_blank" className={`localidades-button sp-button ${isActive ? 'active': ''}`}>
				Saenz Peña, Chaco
			</a>
		</>
	)
}
