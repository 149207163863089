import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const useStyles = makeStyles((theme) => ({
	root: {
		background: "#414141",
		paddingTop: "3%",
    paddingBottom: "4%",
		color: "white",
  },
	mainContainer: {
		margin: "auto",
		maxWidth: 1000,

		[theme.breakpoints.between('md', 'lg')]: {
			justifyContent: "center",
		},
	},
 	section : {
		display: "flex",
		justifyContent: "right",
    lineHeight: "0.9em",
		[theme.breakpoints.between('sm', 'md')]: {
			display: "flex",
			justifyContent: "center",
		},
		[theme.breakpoints.down('xs')]: {
			margin: "0 18%"
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: "center !important",
		},
  },
	containerFirstSection : {
		display: "block",
    width: "auto"
	},
	image : {
		[theme.breakpoints.down('sm')]: {
			display: "flex",
			justifyContent: "center"
		},
	},
  p : {
    fontSize: 12,
    fontWeight: 200,
    letterSpacing: "0.5px",
    color: "#e2e2e2",
		textAlign: "justify",
		[theme.breakpoints.down('sm')]: {
			paddingTop:10
		},
  },
	treeViewLabel : {
		fontFamily: "Fira Sans",
    fontSize: 12,
		fontWeight: 200,
		[theme.breakpoints.down('sm')]: {
			padding: "10px 0 10px 4px"
		},
  },
  containerBites: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem"
  },
  imageBites : {
		width: "120px",
    height: "auto"
	},
  pBites : {
    fontSize: 15,
    fontWeight: "bold",
    letterSpacing: "0.5px",
    color: "#e2e2e2",
		textAlign: "justify",
    marginRight: "8px",
		[theme.breakpoints.down('sm')]: {
			paddingTop:10
		},
  }
}));


export default function Footer () {
  const classes = useStyles()

  return (
    <div className={classes.root}>

      <Grid container className={classes.mainContainer}>

        <Grid item md={4} sm={12} className={classes.section}>
          <div className={classes.containerFirstSection}>
						<div className={classes.image}>
							<img 
								src="/images/echeverria-logo-white.png" 
								width="170px"
								height="50px" 
								alt="Logo Echeverria">
							</img>
						</div>
						<p className={classes.p} >
							Somos una inmobiliaria dedicada al alquiler, venta y tasaciones
							de inmuebles con 20 años en el mercado. 
						</p>

            <div className={classes.containerBites}>
              <p className={classes.pBites} >
                Developed by
              </p>
              <a href="https://www.instagram.com/bitesdigital/" target="_blank">
                <img className={classes.imageBites} src="/images/bites-logo-white.png" alt="Logo bites" />
              </a>
						</div>
					</div>
        </Grid>

        <Grid item md={4} sm={6} xs={12} className={classes.section} style={{justifyContent: "center"}}>
					<TreeView
						defaultCollapseIcon={<ExpandMoreIcon />}
						defaultExpandIcon={<ChevronRightIcon />}
						style={{paddingTop:12}}
					>
						<TreeItem nodeId="1" label="Alquileres" classes={{label: classes.treeViewLabel}}>
							<TreeItem nodeId="2" label="First item" classes={{label: classes.treeViewLabel}} />
						</TreeItem>

						<TreeItem nodeId="3" label="Ventas de inmuebles" classes={{label: classes.treeViewLabel}}>
							<TreeItem nodeId="4" label="First item" classes={{label: classes.treeViewLabel}} />
						</TreeItem>

						<TreeItem nodeId="5" label="Tasaciones" classes={{label: classes.treeViewLabel}}>
							<TreeItem nodeId="6" label="First item" classes={{label: classes.treeViewLabel}} />
						</TreeItem>
						
						<TreeItem nodeId="7" label="Asesoramiento jurídico" classes={{label: classes.treeViewLabel}}>
							<TreeItem nodeId="8" label="First item" classes={{label: classes.treeViewLabel}} />
						</TreeItem>
					</TreeView>
        </Grid>

        <Grid item md={4} sm={6} xs={12} className={classes.section} style={{justifyContent: "right"}}>
          <p className={classes.p}>
            <b>Resistencia</b><br/>
            Av. Sarmiento 906<br/>
            (0362) 4450222 / 154283839<br/><br/>

            <b>Saenz Peña</b><br/>
            Mariano Moreno 854<br/>
            (0364) 4422521 / 154698336
          </p>
        </Grid>

      </Grid>

    </div>
  )
}
